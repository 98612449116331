
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonCardSubtitle, IonToolbar, IonCard, IonCardContent, IonButton, IonSpinner, IonCardHeader, IonCardTitle } from '@ionic/vue';
import axios from 'axios';
import { defineComponent } from 'vue';
import moment from "moment";

export default defineComponent({
  name: 'createCheckoutSession',
  data () {
    return {
      loading: true,
      stripeUrl: null,
      inserat: null,
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonCardSubtitle,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonButton,
    IonSpinner,
    IonCardHeader,
    IonCardTitle
  },
  created() {
      this.finishedLoading = true;


  },
  mounted() {

    const formData = new FormData();
    formData.append("payment", this.$route.params.id);
    axios.post(process.env.VUE_APP_API_URL + '/create-checkout-session', formData, {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
    .then(result => {
          this.stripeUrl = result.data.sessionUrl;
          const inseratId = result.data.inserat;

          axios.get(process.env.VUE_APP_API_URL + '/inserats/' + inseratId).then(response => {
              this.inserat = response.data;
          }).finally(() => {
            this.loading = false;
          })
    });

  },
  methods: {
    submit(){
        window.location.href = this.stripeUrl;
    },
    convertCreatedAt(date: string){
      return moment(date).format("DD.MM.Y");
    },
    convertCreatedAtTime(date: string){
      return moment(date).format("HH:MM");
    },
  }
});

