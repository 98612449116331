<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title v-if="!loading">{{ inserat.title }} Payment</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true" v-if="!loading">
      
      <ion-card>
        <img :src="inserat.inseratImages[0].accessUrl" alt="thumbnail">
        <ion-card-header>
          <ion-card-subtitle>@{{ inserat.createdBy.username }} am {{ convertCreatedAt(inserat.createdAt) }} um {{ convertCreatedAtTime(inserat.createdAt) }} Uhr</ion-card-subtitle>
          <ion-card-title>{{ inserat.title }}</ion-card-title>
        </ion-card-header>
        <ion-card-content style="white-space: break-spaces;">
          <div v-if="inserat.description.length<8">{{ inserat.description }}</div>
          <div v-else>{{ inserat.description.substring(0,60)+".." }}</div>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-content>
          <ion-button @click="submit" style="width: 100%;">Jetzt bezahlen!</ion-button>
        </ion-card-content>
      </ion-card>

    </ion-content>

    <ion-content v-if="loading" class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonCardSubtitle, IonToolbar, IonCard, IonCardContent, IonButton, IonSpinner, IonCardHeader, IonCardTitle } from '@ionic/vue';
import axios from 'axios';
import { defineComponent } from 'vue';
import moment from "moment";

export default defineComponent({
  name: 'createCheckoutSession',
  data () {
    return {
      loading: true,
      stripeUrl: null,
      inserat: null,
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonCardSubtitle,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonButton,
    IonSpinner,
    IonCardHeader,
    IonCardTitle
  },
  created() {
      this.finishedLoading = true;


  },
  mounted() {

    const formData = new FormData();
    formData.append("payment", this.$route.params.id);
    axios.post(process.env.VUE_APP_API_URL + '/create-checkout-session', formData, {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
    .then(result => {
          this.stripeUrl = result.data.sessionUrl;
          const inseratId = result.data.inserat;

          axios.get(process.env.VUE_APP_API_URL + '/inserats/' + inseratId).then(response => {
              this.inserat = response.data;
          }).finally(() => {
            this.loading = false;
          })
    });

  },
  methods: {
    submit(){
        window.location.href = this.stripeUrl;
    },
    convertCreatedAt(date: string){
      return moment(date).format("DD.MM.Y");
    },
    convertCreatedAtTime(date: string){
      return moment(date).format("HH:MM");
    },
  }
});

</script>

<style scoped>


</style>